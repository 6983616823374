<template>
  <div >
    <div class="home">
      <div class="box">
        <div class="left">
          <div class="title" :class="index === 0 ? 'index' : ''" @click="titleClick(0)">0</div>
          <div class="title" :class="index === 1 ? 'index' : ''" @click="titleClick(1)">1</div>
          <div class="title" :class="index === 2 ? 'index' : ''" @click="titleClick(2)">2</div>
          <div class="title" :class="index === 3 ? 'index' : ''" @click="titleClick(3)">3</div>
          <div class="title" :class="index === 4 ? 'index' : ''" @click="titleClick(4)">4</div>
          <div class="title" :class="index === 5 ? 'index' : ''" @click="titleClick(5)">5</div>
        </div>
        <div class="right">
          <div class="content" style="height:200px; background-color: rgb(225, 0, 100);">0</div>
          <div class="content" style="height:800px; background-color: rgb(0, 0, 0);">1</div>
          <div class="content" style="height:500px; background-color: rgb(225, 0, 20);">2</div>
          <div class="content" style="height:1000px; background-color: rgb(225, 171, 0);">3</div>
          <div class="content" style="height:700px; background-color: rgb(221, 200, 20);">4</div>
          <div class="content" style="height:400px; background-color: rgb(150, 0, 150);">5</div>
        </div>
      </div>
    </div>
    <div>
      <!-- 购物车数据渲染 -->
      <div v-for="(item,index) in arr" :key="index">
        <p>
          <input type="checkbox" v-model="item.ischeck" @click="changeOne(index)">
          {{item.name}}  -------价格：{{item.price}}</p>
      </div>
      <!-- 底部 -->
      <div class="footer">
        <input type="checkbox" v-model="allcheck">全选---
      </div>
    </div>
<!--    <form οnsubmit="return false;">-->
<!--      <div class="form-horizontal">-->
<!--        <div class="form-group">-->
<!--          <div class="col-sm-3"><label for="" class="label">提现地址</label></div>-->
<!--          <div class="hidden-input">-->
<!--            &lt;!&ndash;让浏览器自动填充到这个input&ndash;&gt;-->
<!--            <input type="text" class="form-control">-->
<!--          </div>-->
<!--          <div class="col-sm-9">-->

<!--            <input type="text" autocomplete="off" class="form-control bg-transparent" placeholder="提现地址">-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="form-group">-->
<!--          <div class="col-sm-3"><label for="" class="label">备注</label></div>-->
<!--          <div class="col-sm-9">-->

<!--            <input id="inputId" type="text" autocomplete="off" class="form-control bg-transparent" placeholder="备注">-->
<!--            <div class="hidden-input">-->
<!--              &lt;!&ndash;让浏览器自动填充到这个input&ndash;&gt;-->
<!--              <input type="text" class="form-control">-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="form-group mb-10">-->
<!--          <div class="col-sm-3"><label for="" class="label">交易密码</label></div>-->
<!--          <div class="col-sm-9">-->
<!--            <div class="hidden-input">-->
<!--              &lt;!&ndash;让浏览器自动填充到这个input&ndash;&gt;-->
<!--              <input type="password" class="form-control">-->
<!--            </div>-->
<!--            <input type="password" autocomplete="off" class="form-control bg-transparent"placeholder="交易密码">-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="form-group pt-10 no-mb">-->
<!--          <div class="clearfix">-->
<!--            <div class="col-xs-12">-->
<!--              <button type="button" class="btn btn-primary btn-lg btn-block">确定提交</button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </form>-->
    <div class="box">
      <div class="btn">
        <span @click="Submit(1)">按钮一</span>
        <span @click="Submit(2)">按钮二</span>
        <span @click="Submit(3)">按钮三</span>
        <span @click="Submit(4)">按钮四</span>
        <span @click="Submit(5)">按钮五</span>
      </div>
      <div class="page">
        <div id="page1" style="background:red;"></div>
        <div id="page2" style="background:blue;"></div>
        <div id="page3" style="background:skyblue;"></div>
        <div id="page4" style="background:pink;"></div>
        <div id="page5" style="background:green;"></div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import E from "wangeditor"
export default {
  data() {
    return {
      // 节流
      time: undefined,
      // 定时器
      timeout: undefined,
      // 当前高亮的 title 标志
      index: 0,
      // 右侧滚动区域 DOM
      rightDiv: undefined,
      // 右侧内容 DOM 数组
      content: undefined,
      editor: null,
      editorContent:'',
      arr:[
        {
          ischeck:false, //默认为false
          name:'篮球',
          price:98
        },
        {
          ischeck:false, //默认为false
          name:'足球',
          price:10
        },
        {
          ischeck:false, //默认为false
          name:'羽毛球',
          price:5
        },
        {
          ischeck:false, //默认为false
          name:'排球',
          price:100
        }
      ],
      allcheck:false,
    }
  },
  mounted() {

    // 获取 DOM，也可以使用 ref 获取
    this.rightDiv = document.querySelector('.right')
    this.content = document.querySelectorAll('.content')

    // 注意：避免过多的消耗计算机资源，对于这类方法应使用 节流 进行限制
    // throttle 是 lodash 提供的节流方法
    this.time = _.throttle(this.rightScroll, 100)
    // 滚动监听
    this.rightDiv.addEventListener('scroll', this.time);

  },
  destroyed() {
    // 取消定时器
    clearTimeout(this.timeout)
    // 取消节流
    this.time.cancel()
  },
  methods: {
    //判断全选框是否选中
    all(){
      let _index=this.arr.findIndex(item=>{
        return !item.ischeck
      })
      if(_index==-1){
        this.allcheck=true
      }else{
        this.allcheck=false
      }
    },
    //点击购物车数据中单选框改变ischeck事件
    changeOne(index){
      this.arr[index].ischeck=!this.arr[index].ischeck
      //调用判断
      this.all()
    },
    // 滚动监听回调
    rightScroll() {
      for (let i = 0; i < this.content.length; i++) {
        // 判断元素顶部距离父元素的距离 小于等于 父元素卷去的高度，则元素已经显示到顶部了
        if (this.content[i].offsetTop <= this.rightDiv.scrollTop) {
          this.index = i
        }
      }
      // 判断如果滚动条到底了，则高亮最后一个标题
      if (this.rightDiv.scrollTop + this.rightDiv.clientHeight === this.rightDiv.scrollHeight) {
        this.index = this.content.length - 1
      }
    },
    // 点击滚动到对应位置
    titleClick(index) {
      clearTimeout(this.timeout)
      this.index = index
      this.rightDiv.removeEventListener('scroll', this.time)

      // 滚动到指定的位置
      this.scrollToElem(this.content[index], 500)

      this.timeout = setTimeout(() => {
        this.rightDiv.addEventListener('scroll', this.time)
      }, 600)
    },
    /**
     * 滚动到指定的位置
     * @param {String} elem DOM元素
     * @param {Number} duration  滚动动画执行的时间
     */
    scrollToElem(elem, duration) {
      // 初始位置
      const startingY = this.rightDiv.scrollTop

      // 需要滚动的距离
      const diff = elem.offsetTop - startingY
      if (!diff) return

      const that = this
      // 贝赛尔曲线 (滚动动画)
      const easing = x => 1 - Math.pow(1 - x, 4)
      // 初始滚动时间
      let start
      window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp
        // 计算时间的差值， 根据差值计算偏移量
        const time = timestamp - start
        let percent = Math.min(time / duration, 1)

        percent = easing(percent)
        that.rightDiv.scrollTo(0, startingY + diff * percent)

        if (time < duration) {
          window.requestAnimationFrame(step)
        }
      })
    },
    Submit (key) {
      debugger
      // 获取点击的按钮对应页面的id
      var PageId = document.querySelector('#page' + key)
      // 打印出对应页面与窗口的距离
      console.log(PageId.offsetTop)
      // 使用平滑属性，滑动到上方获取的距离
      // 下方我只设置了top，当然 你也可以加上 left 让他横向滑动
      // widow 根据浏览器滚动条，如果你是要在某个盒子里面产生滑动，记得修改
      window.scrollTo({
        'top': PageId.offsetTop,
        'behavior': 'smooth'
      })
    },
    created(){
      document.getElementById('inputId').value='';
    }
  }
}

window.load = function(){
  document.getElementById('inputId').value='';
};
</script>

<style>
.box{
  width: 100%;
}
.page{
  width: 100%
}
.page div{
  width: 100%;
  height:200px;
}
.home {
  display: flex;
  justify-content: center;
  padding: 20px;
}
/*.box {*/
/*  width: 1000px;*/
/*  height: 800px;*/
/*  display: flex;*/
/*  overflow: hidden;}*/
/*.left {*/
/*  width: 30%;}*/
/*.title {*/
/*  width: 100%;*/
/*  height: 50px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  border-bottom: 1px solid;*/
/*}*/
/*.index {*/
/*  background-color: aqua;*/
/*}*/
/*.right {*/
/*  width: 70%;*/
/*  position: relative;*/
/*  overflow-y: hidden;}*/
/*.content {*/
/*  width: 100%;*/
/*  height: 400px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  color: #fff;*/
/*  background-color: rgb(221, 20, 20);*/
/*}*/
/*.hidden-input{*/
/*  position: relative;*/
/*  width: 0;*/
/*  height: 0;*/
/*  overflow: hidden;*/
/*}*/
/*!*让input看不见，而不是直接display: none，如果直接display: none，则不生效*!*/
/*.hidden-input .form-control{*/
/*  position: absolute;*/
/*  left: -1000px;*/
/*}*/
</style>
